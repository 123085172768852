<template>
    <div>
        <h1 class="page__title">Создать FAQ</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-name" class="form-label">Название <sup>*</sup></label>
                    <input
                        id="form-name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.name"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-order" class="form-label">Позиция сортировки <sup>*</sup></label>
                    <input
                        id="form-order"
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.order"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Описание <sup>*</sup></label>
                    <CustomWyswig v-model="form.body" />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="/faq" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
                    <button type="button" class="btn btn-primary mr-2 mb-2" @click="() => save(true)">
                        Сохранить и продолжить
                    </button>
                    <button type="button" class="btn btn-primary mb-2" @click="save(false)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';

export default {
    components: { UploadImage, Preloader, CustomWyswig },
    name: 'Create',
    mixins: [errorResponse, formPreparation],
    data() {
        return {
            affilates: [],
            loading: false,
            form: {
                name: '',
                body: '',
                order: 1,
            },
        };
    },
    methods: {
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async save(flag) {
            this.loading = true;
            try {
                this.loading = false;
                const formData = this.formPreparation();
                const { data } = await this.axios.post('/faqs', formData);

                if (flag) {
                    await this.$router.push({ name: 'faqEdit', params: { id: data.data.id } });
                    this.$notify('Запись добавлена');
                } else {
                    await this.$router.push({ name: 'faq' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
    },
};
</script>

<style></style>
